/* eslint-disable import/prefer-default-export */
import fetchRequest from '../helpers/fetchRequest';

export async function getSiteBackground() {
  const url = '/site-background';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getSongPicture(artist, song) {
  const url = '/streams/get-song-pic';
  const options = {
    method: 'POST',
    body: JSON.stringify({"artist": artist, "song": song}),
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}

export async function getVideoStreamSrc() {
  const url = '/video-stream-src';
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return fetchRequest(url, options);
}
