import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { Swiper, SwiperSlide } from 'swiper/react';
import BlockContent from '../../components/BlockContent';
import BlockChart from '../../components/BlockChart';
import ItemChart from '../../containers/ItemChart';
import PlaylistContainer from '../../components/PlaylistContainer';
import BannerWidget from '../../components/BannerWidget';
import { Link } from 'react-router-dom';
import {
  getApplicantTracks,
  getCurrentBanner,
  // getFlyawayChart,
  getNoveltyChart,
  getPlaylistChart,
  getPlaylistChartNew,
  getPlaylistChartTest,
} from './requests';
import './index.css';
import SvgArrow from '../../components/Svg/SvgArrow';
import Animation from '../../helpers/animation';
import {
  PLAYLIST, 
  NOVELTY, 
  NOVELTY_ICON, 
  PLAYLIST_ICON,  
  APPLICANTS, 
  APPLICANTS_ICON 
} from '../../constants/routes';

export default function PlaylistDetail() {
  // const [flyawayChartData, setFlyawayChartData] = useState([]);
  const [playlistChartData, setPlaylistChartData] = useState([]);
  const [noveltyChartData, setNoveltyChartData] = useState([]);
  const [applicantChartData, setApplicantChartData] = useState([]);
  const [bannerData, setBannerData] = useState(null);
  const [month, setMonth] = useState([]);
  const [clickedDate, setClickedDate] = useState(6);
  const [clickedTime, setClickedTime] = useState(0);
  const [timePosition, setTimePosition] = useState({ position: 0, index: 0 });
  const [datePosition, setDatePosition] = useState({ position: 0, index: 0 });
  const [startEndData, setStartEndData] = useState("");
  const [swiper, setSwiper] = useState(null);
  const [dateHeight, setDateHeight] = useState(0);
  // let filterContainerWidth, timeStyleMeasures;
  const [timeStyleMeasures, setTimeStyleMeasures] = useState(0);
  const [translateTimeValue, setTranslateTimeValue] = useState(0);
  // const [filterContainerWidth, setFilterContainerWidth] = useState(0);

  const newDate = new Date();
  const hoursNow = newDate.getHours().toString().length === 1
    ? `0${newDate.getHours().toString()}`
    : newDate.getHours().toString();
  const minutesNow = newDate.getMinutes().toString().length === 1
    ? `0${newDate.getMinutes().toString()}`
    : newDate.getMinutes().toString();

    // const translateTimeValue = 80.7;
    const translateDateValue = 101.3;

  async function processingRequest() {
    // setFlyawayChartData((await getFlyawayChart()).data);
    //setPlaylistChartData((await getPlaylistChartNew(startEndData)).data);
    await sortPlaylist();
    setNoveltyChartData((await getNoveltyChart()).data);
    setBannerData((await getCurrentBanner()).data);
    setApplicantChartData((await getApplicantTracks()).data);
    // setFilterContainerWidth(Math.ceil(filterContainerElementRef.current.clientWidth - 110));
    setTimeStyleMeasures(Math.ceil(timeElementRef.current.clientWidth));
    setTranslateTimeValue(Math.ceil(timeElementRef.current.clientWidth));
    setDateHeight(Math.ceil(dateElementRef.current.clientWidth))
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    processingRequest();
  }, []);

  const getLastMonth = () => {
    const oneDayInMilliseconds = 86400000;
    const dateNow = Date.now();
    const monthArray = [];
    for (let i = 0; i < 7; i += 1) {
      const date = dateNow - i * oneDayInMilliseconds;
      const month = (new Date(date).getMonth() + 1).toString().length === 1
        ? `0${new Date(date).getMonth() + 1}`
        : new Date(date).getMonth() + 1;
      const dateMonth = new Date(date).getDate().toString();
      const monthDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const monthDay = monthDays[new Date(date).getDay()];
      monthArray.push({ date: `${dateMonth.length === 1 ? 0 + dateMonth : dateMonth}.${month}`, monthDay });
    }
    return monthArray.reverse();
  };

  useEffect(() => {
    const getMonth = getLastMonth();
    setMonth([...getMonth]);
  }, []);


  function setInitialDateTimePosition() {
    setDatePosition(() => ({
      position: 0 - (clickedDate - 6) * translateDateValue,
      index: clickedDate - 6,
    }));

    const hour = new Date;
    const hourStr = hour.getHours().toString();
    const hourStrFull = hourStr + ":00";

    if (hours.indexOf(hourStrFull) != -1) {
      setTimePosition(() => ({
        position: 0 - (hours.indexOf(hourStrFull) - 7) * (translateTimeValue + 3), // 3 - margin-right
        index: hours.indexOf(hourStrFull) - 7,
      }));
      setClickedTime(hours.indexOf(hourStrFull));
    }
  }

  useEffect(() => {
    setInitialDateTimePosition();
  }, [translateDateValue, translateTimeValue]);

  const hours = [
    '00:00',
    '01:00',
    '02:00',
    '03:00',
    '04:00',
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];

  const timeScrollHandler = (side) => {
    if (side === 'right') {
      return timePosition.index !== hours.length - 8
        ? setTimePosition((prev) => ({
          position: prev.position - (translateTimeValue + 3),
          index: prev.index + 1,
        }))
        : null;
    }
    if (side === 'left') {
      return timePosition.index !== 0
        ? setTimePosition((prev) => ({
          position: prev.position + (translateTimeValue + 3),
          index: prev.index - 1,
        }))
        : null;
    }
    return 1;
  };

  // const dateScrollHandler = (side) => {
  //   if (side === 'right') {
  //     return datePosition.index !== month.length - 7
  //       ? setDatePosition((prev) => ({
  //         position: prev.position - translateDateValue,
  //         index: prev.index + 1,
  //       }))
  //       : null;
  //   }
  //   if (side === 'left') {
  //     return datePosition.index !== 0
  //       ? setDatePosition((prev) => ({
  //         position: prev.position + translateDateValue,
  //         index: prev.index - 1,
  //       }))
  //       : null;
  //   }
  //   return 1;
  // };

  async function sortPlaylist() {
    let chosenDate = new Date();
    chosenDate.setDate(chosenDate.getDate() - (6 - clickedDate));
    chosenDate.setHours(Number.parseInt(hours[clickedTime].substring(0, 2)) + 3); // Add 3 hours for compairing with DB (it saves time with timezone)
    chosenDate.setMinutes(0);
    chosenDate.setSeconds(0);
    chosenDate.setMilliseconds(0);
    let endDate = new Date();
    endDate.setDate(endDate.getDate() - (6 - clickedDate));
    endDate.setHours(Number.parseInt(hours[clickedTime].substring(0, 2)) + 1 + 3); // Add 3 hours for compairing with DB (it saves time with timezone)
    endDate.setMinutes(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    setStartEndData(chosenDate.toISOString().replace(" ", "--") + "---" + endDate.toISOString().replace(" ", "--"));
  }

  useEffect(() => {
    sortPlaylist();
  }, [clickedDate, clickedTime]);

  const rerenderPlaylist = async () => {
    setPlaylistChartData((await getPlaylistChartNew(startEndData)).data);
  }
  useEffect(() => {
    rerenderPlaylist();
  }, [startEndData]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(clickedTime);
    }
  }, [swiper]);

  const dateElementRef = useRef(null);
  const timeElementRef = useRef(null);
  // const filterContainerElementRef = useRef(null);


  // setInterval(() => {
  //   rerenderPlaylist();
  // }, 1000 * 60 * 5);

  return (
    <div className="page-playlist">
      <MetaTags>
        <title>Плейлист - Новое Радио</title>
        <meta name="description" content="Плейлист - Новое Радио" />
      </MetaTags>
      <BlockContent left right>
        <div className="big-column__chart">
          <div className="chart-info">
            <img
              src={PLAYLIST_ICON}
              className="chart-info__icon"
              alt=""
            />
            <span className="chart-info__title">
              <Link className="chart-info__title_link not-link" to={PLAYLIST}>
                Плейлист
              </Link>
            </span>
          </div>
          <div className="big-column__chart_date-filter">
            <div className="date-filter__item_wrapper" style={{ width: `${101.3 * 7}px` }}>
              {month.map((item, index) => (
                <div
                  onClick={() => {
                    setClickedDate(index);
                  }}
                  key={item.date}
                  className={`date-filter_item ${index === clickedDate && 'active'}`}
                  style={{
                    transform: `translateX(${datePosition.position}px)`,
                    height: `${dateHeight}px`,
                  }}
                  ref={dateElementRef}
                >
                  <span className="date-filter_day">{item.monthDay}</span>
                  <span className="date-filter_date">{item.date}</span>
                </div>
              ))}
              </div>
          </div> {/* big-column__chart_date-filter */}

          <div className="big-column__chart_time-filter">
            <div
              onClick={(event) => {
                new Animation(event).button();
                timeScrollHandler('left');
              }}
              className="time-scroll-left"
            >
              <SvgArrow disable={timePosition.index === 0} direction="left" />
            </div>

            <div className="time-filter__item_wrapper">
              {hours.map((item, index) => (
                <div
                  onClick={() => setClickedTime(index)}
                    key={item}
                    className={`time-filter__item ${index === clickedTime && 'active'}`}
                    style={{
                      transform: `translateX(${timePosition.position}px)`,
                      height: `${timeStyleMeasures}px`,
                  }}
                  ref={timeElementRef}
                >
                  <span className="time__filter_hour">{item}</span>
                </div>
              ))}
            </div>

            <div
              onClick={(event) => {
                new Animation(event).button();
                timeScrollHandler('right');
              }}
              className="time-scroll-right"
            >
              <SvgArrow disable={timePosition.index === hours.length - 8} direction="right" />
            </div>
          </div> {/* big-column__chart_time-filter */}

          {playlistChartData != undefined && 
          playlistChartData != null && 
          Object.keys(playlistChartData).length !== 0 != "" && 
          playlistChartData.id && (
            <PlaylistContainer
                chartData={playlistChartData.track_list}
                title={playlistChartData.title}
                link={PLAYLIST}
                icon={PLAYLIST_ICON}
                countViewTracks={playlistChartData.track_list.length}
                additionalClass = {"playlist-detail"}
                children={playlistChartData.track_list}
                type={"playlistChartDetail"}
                color={null}
                isLinkable={false}
              />
          )}
        </div>
        <div className="small-column__chart">
          {noveltyChartData && (
            <PlaylistContainer
                chartData={noveltyChartData}
                title={"Новинки"}
                link={NOVELTY}
                icon={NOVELTY_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={noveltyChartData}
                type={"noveltyChart"}
                color="yellow"
              />
          )}
          {applicantChartData && (
            <PlaylistContainer
                chartData={applicantChartData}
                title={"Претенденты"}
                link={APPLICANTS}
                icon={APPLICANTS_ICON}
                countViewTracks={4}
                additionalClass = {"new_songs"}
                children={applicantChartData}
                type={"applicantChart"}
                color="yellow"
                isLinkable={false}
              />
          )}
          {bannerData && bannerData.map((elem) => (
            <BannerWidget img={elem.picture} redirect={elem.link} />
          ))}
        </div>

      </BlockContent>
    </div>
  );
}
