import {
  CHANGE_FLOW,
  PAUSE,
  PLAY,
  PLAYBACK,
  CHANGE_AUDIO_FLOW,
  CHANGE_AUDIO_PLAYBACK,
  PLAY_AUDIO_PLAYBACK,
  PAUSE_AUDIO_PLAYBACK,
  CHANGE_VOLUME,
  AUDIO_CURRENT_TIME_PERCENT,
  FILTER_PODCASTS,
  SET_DURATION,
  UPDATE_CURRENT_TIME,
  SET_FULL_CURRENT_TIME,
  SET_FULL_DURATION, SET_PLAYING_PODCAST_INDEX, SET_STREAM_NAME,
  IS_PAGE_LOADED,
  IS_VIDEOSTREAM_OPENED,
  IS_VIDEOSTREAM_PLAYING,
  SET_LIKED_TRACKS,
  SET_RATED_TRACKS,
  SET_CURRENT_ARTIST,
  SET_CURRENT_SONG,
  SET_CURRENT_COVER,
  SET_FLOWS_INFO,
  SET_PROGRAM_SLIDE_INDEX,
  SET_LAST_LISTENED_STREAM,
  SET_MENU_OPEN
} from './types';

if (!localStorage.getItem('likedTracks') || !localStorage.getItem('likedTracks').length) {
  localStorage.setItem('likedTracks', []);
}
if (!localStorage.getItem('ratedTracks') || !localStorage.getItem('ratedTracks').length) {
  localStorage.setItem('ratedTracks', []);
}
const likedTracks = localStorage.getItem('likedTracks') != "" ? localStorage.getItem('likedTracks') : [];
const ratedTracks = localStorage.getItem('ratedTracks') != "" ? localStorage.getItem('ratedTracks') : [];

const initialStore = {
  audioFlow: '',
  audioPlayback: false,
  audioCurrentFullTime: 0,
  audioCurrentFullDuration: 0,
  flow: {},
  playback: false,
  volume: 0.1, // don forget to change!!
  likedTracks: localStorage.getItem('likedTracks') != "" ? localStorage.getItem('likedTracks') : [],
  ratedTracks: localStorage.getItem('ratedTracks') != "" ? localStorage.getItem('ratedTracks') : [],
  filteredPodcasts: [],
  duration: '',
  currentTime: '',
  podcastIndex: null,
  streamName: '',
  isPageLoaded: false,
  isVideoStreamOpened: false,
  isVideoStreamPlaying: false,
  currentArtist: '',
  currentSong: '',
  currentCover: '/uploads/images/tracks/novoe_radio_placeholder.jpeg',
  flowsInfo: {},
  programSlideIndex: 0,
  lastListenedStream: "",
  isMenuOpen: false,
};

export default function rootReduser(store = initialStore, action) {
  switch (action.type) {
    case AUDIO_CURRENT_TIME_PERCENT: {
      return { ...store, audioCurrentTimePercent: action.payload };
    }
    case PLAY_AUDIO_PLAYBACK: {
      return { ...store, audioPlayback: true };
    }
    case PAUSE_AUDIO_PLAYBACK: {
      return { ...store, audioPlayback: false };
    }
    case CHANGE_AUDIO_FLOW: {
      return { ...store, audioFlow: action.payload };
    }
    case CHANGE_AUDIO_PLAYBACK: {
      return { ...store, audioPlayback: !store.audioPlayback };
    }
    case CHANGE_FLOW: {
      return { ...store, flow: action.payload };
      // return store;
    }
    case PLAYBACK: {
      return { ...store, playback: !store.playback };
    }
    case PLAY: {
      return { ...store, playback: true };
    }
    case PAUSE: {
      return { ...store, playback: false };
    }
    case CHANGE_VOLUME: {
      return { ...store, volume: action.payload };
    }
    case FILTER_PODCASTS: {
      return { ...store, filteredPodcasts: action.payload };
    }

    // Audio timings
    case SET_FULL_CURRENT_TIME:
      return { ...store, audioCurrentFullTime: action.payload };
    case SET_FULL_DURATION:
      return { ...store, audioCurrentFullDuration: action.payload };
    case SET_DURATION:
      return { ...store, duration: action.payload };
    case UPDATE_CURRENT_TIME:
      return { ...store, currentTime: action.payload };
    case SET_PLAYING_PODCAST_INDEX:
      return { ...store, podcastIndex: action.payload };

    // Stream metadata
    case SET_STREAM_NAME:
      return { ...store, streamName: action.payload };

    // Loading page on client side
    case IS_PAGE_LOADED: 
      return {...store, isPageLoaded: action.payload };
    case IS_VIDEOSTREAM_OPENED:
      return {...store, isVideoStreamOpened: action.payload };

    case IS_VIDEOSTREAM_PLAYING:
      return {...store, isVideoStreamPlaying: action.payload };

    case SET_LIKED_TRACKS:
      return {...store, likedTracks: action.payload}
    case SET_RATED_TRACKS:
      return {...store, ratedTracks: action.payload}
    case SET_CURRENT_ARTIST:
      return {...store, currentArtist: action.payload}
    case SET_CURRENT_SONG:
      return {...store, currentSong: action.payload}
    case SET_CURRENT_COVER:
      return {...store, currentCover: action.payload ? action.payload.replace("static", "") : '/uploads/images/tracks/novoe_radio_placeholder.jpeg'}
    case SET_FLOWS_INFO: 
      return {...store, flowsInfo: {...store.flowsInfo, ...action.payload}}
    case SET_PROGRAM_SLIDE_INDEX: 
      return {...store, programSlideIndex: action.payload}
    case SET_LAST_LISTENED_STREAM:
      return {...store, lastListenedStream: action.payload}
    case SET_MENU_OPEN:
      return {...store, isMenuOpen: action.payload}
    default:
      return store;
  }
}
