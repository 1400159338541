import React, {useState} from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReduser from './redux/redusers';
import Dashboard from './templates/Dashboard';
import NewsPage from './pages/News';
import CreateNews from './pages/News/CustomNews/CreateNews';
import UpdateNews from './pages/News/CustomNews/UpdateNews';
import OffersPage from './pages/Offers';
import CreateOffers from './pages/Offers/CustomOffers/CreateOffers';
import UpdateOffers from './pages/Offers/CustomOffers/UpdateOffers';
import TracksPage from './pages/Tracks';
import CreateTracks from './pages/Tracks/CustomTracks/CreateTracks';
import UpdateTracks from './pages/Tracks/CustomTracks/UpdateTracks';
import PlaylistsPage from './pages/Playlists';
import CreatePlaylists from './pages/Playlists/CustomPlaylists/CreatePlaylists';
import UpdatePlaylists from './pages/Playlists/CustomPlaylists/UpdatePlaylists';
import PodcastsPage from './pages/Podcasts';
import CreatePodcasts from './pages/Podcasts/CustomPodcasts/CreatePodcasts';
import UpdatePodcasts from './pages/Podcasts/CustomPodcasts/UpdatePodcasts';
import ProgramsPage from './pages/Programs';
import CreatePrograms from './pages/Programs/CustomPrograms/CreatePrograms';
import UpdatePrograms from './pages/Programs/CustomPrograms/UpdatePrograms';
import EmceesPage from './pages/Emcees';
import CreateEmcees from './pages/Emcees/CustomEmcees/CreateEmcees';
import UpdateEmcees from './pages/Emcees/CustomEmcees/UpdateEmcees';
import QuizPage from './pages/Quiz';
import CreateQuiz from './pages/Quiz/CustomQuiz/CreateQuiz';
import UpdateQuiz from './pages/Quiz/CustomQuiz/UpdateQuiz';
// import GuestsPage from './pages/Guests';
// import CreateGuests from './pages/Guests/CustomGuests/CreateGuests';
// import UpdateGuests from './pages/Guests/CustomGuests/UpdateGuests';
import BannersPage from './pages/Banners';
import CreateBanners from './pages/Banners/CustomBanners/CreateBanners';
import UpdateBanners from './pages/Banners/CustomBanners/UpdateBanners';
import SeoPage from './pages/Seo';
import UpdateSeoNews from './pages/Seo/CustomSeo/UpdateSeoNews';
import UpdateSeoOffers from './pages/Seo/CustomSeo/UpdateSeoOffers';
import UpdateSeoEmcees from './pages/Seo/CustomSeo/UpdateSeoEmcees';
// import UpdateSeoPrograms from './pages/Seo/CustomSeo/UpdateSeoPrograms';
import Settings from './pages/Settings';
import UpdateSettingsSiteBackground from './pages/Settings/CustomSettings/SiteBackground';
import LogoSetting from './pages/Settings/CustomSettings/MainLogo';
import Socials from './pages/Settings/Socials';
import CreateSocial from './pages/Settings/Socials/CustomSocials/CreateSocial';
import UpdateSocial from './pages/Settings/Socials/CustomSocials/UpdateSocial';
import SlidesPage from './pages/Slides';
import CreateSlide from './pages/Slides/CustomSlides/CreateSlide';
import UpdateSlide from './pages/Slides/CustomSlides/UpdateSlide';
import About from './pages/About/index';
import CreateAboutBlock from './pages/About/CustomAbout/CreateAboutBlock';
import UpdateAboutBlock from './pages/About/CustomAbout/UpdateAboutBlock';
import HitChart from './pages/HitChart';
import NoveltyChart from './pages/NoveltyChart';
import ApplicantChart from './pages/ApplicantChart';
import LeftBanner from './pages/Settings/CustomSettings/LeftBanner';
import PollSettings from "./pages/Settings/CustomSettings/PollSettings";

import AudioStreamAll from './pages/AudioStream';
import AudioStreamCreate from './pages/AudioStream/CustomStream/AudioStreamCreate';
import AudioStreamUpdate from './pages/AudioStream/CustomStream/AudioStreamUpdate';
import VideoStreamAll from './pages/VideoStream';
import VideoStreamCreate from './pages/VideoStream/CustomStream/VideoStreamCreate';
import VideoStreamUpdate from './pages/VideoStream/CustomStream/VideoStreamUpdate';
import UsersAll from './pages/Users';
import UsersCreate from './pages/Users/CustomUsers/CreateUser';
import UsersUpdate from './pages/Users/CustomUsers/UpdateUser';
import LoginForm from "./components/LoginForm";

import {
  ADMIN_NEWS_ROUTE,
  ADMIN_NEWS_CREATE_ROUTE,
  ADMIN_NEWS_UPDATE_ROUTE,
  ADMIN_OFFERS_ROUTE,
  ADMIN_OFFERS_CREATE_ROUTE,
  ADMIN_OFFERS_UPDATE_ROUTE,
  ADMIN_TRACKS_ROUTE,
  ADMIN_TRACKS_CREATE_ROUTE,
  ADMIN_TRACKS_UPDATE_ROUTE,
  ADMIN_PLAYLISTS_ROUTE,
  ADMIN_PLAYLISTS_CREATE_ROUTE,
  ADMIN_PLAYLISTS_UPDATE_ROUTE,
  ADMIN_PODCASTS_ROUTE,
  ADMIN_PODCASTS_CREATE_ROUTE,
  ADMIN_PODCASTS_UPDATE_ROUTE,
  ADMIN_PROGRAMS_ROUTE,
  ADMIN_PROGRAMS_CREATE_ROUTE,
  ADMIN_PROGRAMS_UPDATE_ROUTE,
  ADMIN_EMCEES_ROUTE,
  ADMIN_EMCEES_CREATE_ROUTE,
  ADMIN_EMCEES_UPDATE_ROUTE,
  ADMIN_QUIZ_ROUTE,
  ADMIN_QUIZ_CREATE_ROUTE,
  ADMIN_QUIZ_UPDATE_ROUTE,
  // ADMIN_GUESTS_ROUTE,
  // ADMIN_GUESTS_CREATE_ROUTE,
  // ADMIN_GUESTS_UPDATE_ROUTE,
  ADMIN_BANNERS_ROUTE,
  ADMIN_BANNERS_CREATE_ROUTE,
  ADMIN_BANNERS_UPDATE_ROUTE,
  ADMIN_SEO_ROUTE,
  ADMIN_SEO_NEWS_UPDATE_ROUTE,
  ADMIN_SEO_OFFERS_UPDATE_ROUTE,
  ADMIN_SEO_EMCEES_UPDATE_ROUTE,
  ADMIN_SLIDES_ROUTE,
  ADMIN_SLIDES_CREATE_ROUTE,
  ADMIN_SLIDES_UPDATE_ROUTE,
  // ADMIN_SEO_PROGRAMS_UPDATE_ROUTE,
  ADMIN_SETTING_ROUTE,
  ADMIN_SETTING_SITE_BACKGROUND_ROUTE,
  ADMIN_SETTING_LOGO_ROUTE,
  ADMIN_SETTING_SOCIALS_ROUTE,
  ADMIN_SETTING_SOCIALS_CREATE_ROUTE,
  ADMIN_SETTING_SOCIALS_UPDATE_ROUTE,
  ADMIN_SETTING_LEFT_BANNER_ROUTE,
  ADMIN_SETTING_POOL_SETTINGS_ROUTE,
  ADMIN_ABOUT_ROUTE,
  ADMIN_ABOUT_CREATE_ROUTE,
  ADMIN_ABOUT_UPDATE_ROUTE,
  ADMIN_CHART_ROUTE,
  ADMIN_STREAMS_A_ROUTE,
  ADMIN_STREAMS_A_CREATE_ROUTE,
  ADMIN_STREAMS_A_UPDATE_ROUTE,
  ADMIN_STREAMS_V_ROUTE,
  ADMIN_STREAMS_V_CREATE_ROUTE,
  ADMIN_STREAMS_V_UPDATE_ROUTE,
  ADMIN_GO_TO_WEBSITE,
  ADMIN_USERS_ROUTE,
  ADMIN_USERS_CREATE_ROUTE,
  ADMIN_USERS_UPDATE_ROUTE,
  ADMIN_NOVELTY_ROUTE,
  ADMIN_APPLICANT_ROUTE,
} from './constants/routes';

const store = createStore(rootReduser);

export default function Admin() {
  const history = useHistory();
  const [isLogged, setIsLogged] = useState(false);

  // const [renderString, setRenderString] = useState(<LoginForm parentLoginHandler={parentLoginHandler}></LoginForm>);

  const parentLoginHandler = () => {
    setIsLogged(true);
  }

  return (
    <div>
      {!isLogged && ( 
      <LoginForm parentLoginHandler={parentLoginHandler}></LoginForm>
      )}
      {isLogged && (
       <Provider store={store}>
        
        <Dashboard history={history}>
          <Switch>
            <Route exact path={ADMIN_TRACKS_ROUTE} component={TracksPage} />
            <Route exact path={ADMIN_OFFERS_ROUTE} component={OffersPage} />
            <Route exact path={ADMIN_NEWS_ROUTE} component={NewsPage} />
            <Route path={ADMIN_NEWS_CREATE_ROUTE} component={CreateNews} />
            <Route path={ADMIN_NEWS_UPDATE_ROUTE} component={UpdateNews} />
            <Route path={ADMIN_OFFERS_CREATE_ROUTE} component={CreateOffers} />
            <Route path={ADMIN_OFFERS_UPDATE_ROUTE} component={UpdateOffers} />
            <Route path={ADMIN_TRACKS_CREATE_ROUTE} component={CreateTracks} />
            <Route path={ADMIN_TRACKS_UPDATE_ROUTE} component={UpdateTracks} />
            <Route exact path={ADMIN_PLAYLISTS_ROUTE} component={PlaylistsPage} />
            <Route path={ADMIN_PLAYLISTS_CREATE_ROUTE} component={CreatePlaylists} />
            <Route
              path={ADMIN_PLAYLISTS_UPDATE_ROUTE}
              component={UpdatePlaylists}
            />
            <Route exact path={ADMIN_PODCASTS_ROUTE} component={PodcastsPage} />
            <Route
              path={ADMIN_PODCASTS_CREATE_ROUTE}
              component={CreatePodcasts}
            />
            <Route
              path={ADMIN_PODCASTS_UPDATE_ROUTE}
              component={UpdatePodcasts}
            />
            <Route exact path={ADMIN_PROGRAMS_ROUTE} component={ProgramsPage} />
            <Route
              path={ADMIN_PROGRAMS_CREATE_ROUTE}
              component={CreatePrograms}
            />
            <Route
              exact
              path={ADMIN_PROGRAMS_UPDATE_ROUTE}
              component={UpdatePrograms}
            />
            <Route exact path={ADMIN_EMCEES_ROUTE} component={EmceesPage} />
            <Route path={ADMIN_EMCEES_CREATE_ROUTE} component={CreateEmcees} />
            <Route path={ADMIN_EMCEES_UPDATE_ROUTE} component={UpdateEmcees} />
            <Route exact path={ADMIN_QUIZ_ROUTE} component={QuizPage} />
            <Route path={ADMIN_QUIZ_CREATE_ROUTE} component={CreateQuiz} />
            <Route path={ADMIN_QUIZ_UPDATE_ROUTE} component={UpdateQuiz} />
            {/* <Route exact path={ADMIN_GUESTS_ROUTE} component={GuestsPage} /> */}
            {/* <Route path={ADMIN_GUESTS_CREATE_ROUTE} component={CreateGuests} /> */}
            {/* <Route path={ADMIN_GUESTS_UPDATE_ROUTE} component={UpdateGuests} /> */}
            <Route exact path={ADMIN_BANNERS_ROUTE} component={BannersPage} />
            <Route path={ADMIN_BANNERS_CREATE_ROUTE} component={CreateBanners} />
            <Route path={ADMIN_BANNERS_UPDATE_ROUTE} component={UpdateBanners} />
            <Route exact path={ADMIN_SEO_ROUTE} component={SeoPage} />
            <Route path={ADMIN_SEO_NEWS_UPDATE_ROUTE} component={UpdateSeoNews} />
            <Route
              path={ADMIN_SEO_OFFERS_UPDATE_ROUTE}
              component={UpdateSeoOffers}
            />
            <Route
              path={ADMIN_SEO_EMCEES_UPDATE_ROUTE}
              component={UpdateSeoEmcees}
            /> 
            {/* <Route */}
            {/*  path={ADMIN_SEO_PROGRAMS_UPDATE_ROUTE} */}
            {/*  component={UpdateSeoPrograms} */}
            {/* /> */}

            <Route exact path={ADMIN_STREAMS_A_ROUTE} component={AudioStreamAll} />
            <Route exact path={ADMIN_STREAMS_A_CREATE_ROUTE} component={AudioStreamCreate} />
            <Route exact path={ADMIN_STREAMS_A_UPDATE_ROUTE} component={AudioStreamUpdate} />
            <Route exact path={ADMIN_STREAMS_V_ROUTE} component={VideoStreamAll} />
            <Route exact path={ADMIN_STREAMS_V_CREATE_ROUTE} component={VideoStreamCreate} />
            <Route exact path={ADMIN_STREAMS_V_UPDATE_ROUTE} component={VideoStreamUpdate} />

            <Route exact path={ADMIN_SLIDES_CREATE_ROUTE} component={CreateSlide} />
            <Route exact path={ADMIN_SLIDES_UPDATE_ROUTE} component={UpdateSlide} />
            <Route exact path={ADMIN_SLIDES_ROUTE} component={SlidesPage} />
            <Route exact path={ADMIN_SETTING_ROUTE} component={Settings} />
            <Route
              path={ADMIN_SETTING_SITE_BACKGROUND_ROUTE}
              component={UpdateSettingsSiteBackground}
            />
            <Route path={ADMIN_SETTING_LOGO_ROUTE} component={LogoSetting} />
            <Route path={ADMIN_SETTING_SOCIALS_ROUTE} component={Socials} />
            <Route path={ADMIN_SETTING_SOCIALS_CREATE_ROUTE} component={CreateSocial} />
            <Route path={ADMIN_SETTING_SOCIALS_UPDATE_ROUTE} component={UpdateSocial} />
            <Route path={ADMIN_SETTING_LEFT_BANNER_ROUTE} component={LeftBanner} />
            <Route path={ADMIN_SETTING_POOL_SETTINGS_ROUTE} component={PollSettings} />

            <Route exact path={ADMIN_ABOUT_ROUTE} component={About} />
            <Route exact path={ADMIN_ABOUT_CREATE_ROUTE} component={CreateAboutBlock} />
            <Route exact path={ADMIN_ABOUT_UPDATE_ROUTE} component={UpdateAboutBlock} />

            <Route exact path={ADMIN_CHART_ROUTE} component={HitChart} />
            <Route exact path={ADMIN_NOVELTY_ROUTE} component={NoveltyChart} />
            <Route exact path={ADMIN_APPLICANT_ROUTE} component={ApplicantChart} />
            <Route exact path={ADMIN_USERS_ROUTE} component={UsersAll} />
            <Route exact path={ADMIN_USERS_CREATE_ROUTE} component={UsersCreate} />
            <Route exact path={ADMIN_USERS_UPDATE_ROUTE} component={UsersUpdate} />
          </Switch>
        </Dashboard>
      </Provider>
      )}
    </div>
  );
}
