/* eslint-disable max-len */
import {
  CHANGE_FLOW,
  PAUSE,
  PLAY,
  PLAYBACK,
  CHANGE_AUDIO_FLOW,
  CHANGE_AUDIO_PLAYBACK,
  PLAY_AUDIO_PLAYBACK,
  PAUSE_AUDIO_PLAYBACK,
  CHANGE_VOLUME,
  AUDIO_CURRENT_TIME_PERCENT,
  FILTER_PODCASTS,
  SET_DURATION,
  UPDATE_CURRENT_TIME,
  SET_FULL_DURATION,
  SET_FULL_CURRENT_TIME, SET_PLAYING_PODCAST_INDEX, SET_STREAM_NAME,
  IS_PAGE_LOADED,
  IS_VIDEOSTREAM_OPENED,
  IS_VIDEOSTREAM_PLAYING,
  SET_LIKED_TRACKS,
  SET_RATED_TRACKS,
  SET_FLOWS_INFO,
  SET_PROGRAM_SLIDE_INDEX,
  SET_LAST_LISTENED_STREAM,
  SET_MENU_OPEN,
  SET_CURRENT_ARTIST,
  SET_CURRENT_SONG,
  SET_CURRENT_COVER,
} from './types';

export const changeFlowAction = (flow) => ({
  type: CHANGE_FLOW,
  payload: flow,
});

export const changeAudioFlowAction = (flow) => ({
  type: CHANGE_AUDIO_FLOW,
  payload: flow,
});

export const changeAudioPlaybackAction = () => ({
  type: CHANGE_AUDIO_PLAYBACK,
});

export const playAudioPlaybackAction = () => ({
  type: PLAY_AUDIO_PLAYBACK,
});

export const pauseAudioPlaybackAction = () => ({
  type: PAUSE_AUDIO_PLAYBACK,
});

export const changePlaybackAction = () => ({
  type: PLAYBACK,
});

export const playAction = () => ({
  type: PLAY,
});

export const pauseAction = () => ({
  type: PAUSE,
});

export const changeVolumeAction = (volume) => ({
  type: CHANGE_VOLUME,
  payload: volume,
});

export const changeAudioCurrentTimePercentAction = (currentAudioTime) => ({
  type: AUDIO_CURRENT_TIME_PERCENT,
  payload: currentAudioTime,
});

export const setCurrentFullDurationAction = (duration) => ({ type: SET_FULL_DURATION, payload: duration });
export const setCurrentFullTimeAction = (time) => ({ type: SET_FULL_CURRENT_TIME, payload: time });
export const setCurrentDurationAction = (duration) => ({ type: SET_DURATION, payload: duration });
export const setCurrentTimeAction = (time) => ({ type: UPDATE_CURRENT_TIME, payload: time });
export const setPlayingPodcastIndex = (index) => ({ type: SET_PLAYING_PODCAST_INDEX, payload: index });

export const setStreamName = (name) => ({ type: SET_STREAM_NAME, payload: name });

export const filterPodcasts = (array) => ({ type: FILTER_PODCASTS, payload: array });

export const setLoadedPage = (bool) => ({ type: IS_PAGE_LOADED, payload: bool });

export const setVideoStreamOpen = (bool) =>({ type: IS_VIDEOSTREAM_OPENED, payload: bool });

export const setVideoStreamPlaying = (bool) =>({ type: IS_VIDEOSTREAM_PLAYING, payload: bool })

export const setLikedTracks = (data) => ({type: SET_LIKED_TRACKS, payload: data});
export const setRatedTracks = (data) => ({type: SET_RATED_TRACKS, payload: data});

export const setCurrentArtist = (data) => ({type: SET_CURRENT_ARTIST, payload: data});
export const setCurrentSong = (data) => ({type: SET_CURRENT_SONG, payload: data});
export const setCurrentCover = (data) => ({type: SET_CURRENT_COVER, payload: data});

export const setFlowsInfo = (data) => ({type: SET_FLOWS_INFO, payload: data});

export const setProgramSlideIndex = (data) => ({type: SET_PROGRAM_SLIDE_INDEX, payload: data});
export const setLastListenedStream = (data) => ({type: SET_LAST_LISTENED_STREAM, payload: data});

export const setMenuOpen = (data) => ({type: SET_MENU_OPEN, payload: data});
